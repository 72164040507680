import { graphql } from '../../../../generated/gql';

export const forwardersQuery = graphql(/* GraphQL */ `
  query forwardersQuery($request: AggregatedFilterDataRequest!) {
    aggregatedFilterDataV2(request: $request) {
      forwarderNames {
        id
        value
      }
    }
  }
`);

import { Box, Dialog, TextButton } from '@beacon-devops/components';
import { ModalProvider } from '@components/common/ModalContext/ModalProvider';
import { SelfPaymentStep } from './steps';
import { SelfPaymentStepList } from './stepsList';
import { useSelfPaymentModalContext } from './useSelfPaymentModalContext';
import { trackCloseModal, trackUpgradePlan } from '../../analytics';

interface SelfPaymentModalProps {
  triggerButtonText?: string;
  triggerButtonFontSize?: number;
  /*
    This modal can be triggered in two ways:
    1. During a user's free trial they are able to upgrade at any time, in this case the modal should be closable
    2. When a user's free trial has ended, we show this popup, but it is not closable.
    isClosable is true if the modal is manually triggered, and we want the modal to be closable.
   */
  isClosable: boolean;
  initialStep: SelfPaymentStep;
  trialDaysRemaining?: number;
}

function Modal({ isClosable, triggerButtonText, triggerButtonFontSize, trialDaysRemaining }: SelfPaymentModalProps) {
  const { currentStep, isModalOpen, openModal, closeModal } = useSelfPaymentModalContext();
  const onModalOpen = (): void => {
    trackUpgradePlan(trialDaysRemaining);
    openModal();
  };

  const onModalClose = (): void => {
    trackCloseModal(currentStep.toString());
    closeModal();
  };

  const modalStep = SelfPaymentStepList[currentStep];

  return (
    <>
      <Dialog
        data-testid="self-payment-modal"
        open={isClosable ? isModalOpen : true}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            onModalClose();
          }
        }}
        contentSize="contain"
        trigger={
          isClosable && (
            <TextButton onClick={onModalOpen} fontSize={triggerButtonFontSize} data-testid="trigger-upgrade-button">
              {triggerButtonText || 'Upgrade your plan'}
            </TextButton>
          )
        }
        closeVariant={isClosable ? 'default' : 'hide'}
        closeOnOutsideClick={false}
        title={modalStep?.title}
        titleFontSize={4}
        contentMinWidth="552px"
        contentPadding={6}
      >
        <Box height="auto">{modalStep?.component}</Box>
      </Dialog>
    </>
  );
}

export function SelfPaymentModal({
  triggerButtonText,
  isClosable,
  initialStep,
  trialDaysRemaining,
  triggerButtonFontSize,
}: SelfPaymentModalProps) {
  return (
    <ModalProvider initialStep={initialStep} initialData={{}}>
      <Modal
        isClosable={isClosable}
        triggerButtonText={triggerButtonText}
        initialStep={initialStep}
        trialDaysRemaining={trialDaysRemaining}
        triggerButtonFontSize={triggerButtonFontSize}
      />
    </ModalProvider>
  );
}

import { useState } from 'react';
import { Alert, Text, TextButton } from '@beacon-devops/components';
import { openDiscoveryCall, SelfPaymentModal, SelfPaymentStep } from '@features/selfPayment';
import { trackAlertBookACall, trackCloseCountdownAlert } from '../../analytics';
import { DAYS_REMAINING_WARNING, TRIAL_DISMISSED_KEY, TRIAL_WARNING_THRESHOLD } from '../../constants';

interface Props {
  daysRemaining: number;
}

export function TrialDayCountdownAlert({ daysRemaining }: Props) {
  const isAlertApplicable = () => {
    const dismissedDayRemaining = localStorage.getItem(TRIAL_DISMISSED_KEY);
    return (
      daysRemaining <= TRIAL_WARNING_THRESHOLD &&
      daysRemaining >= 0 &&
      dismissedDayRemaining !== daysRemaining.toString()
    );
  };

  const [showAlert, setShowAlert] = useState<boolean>(isAlertApplicable);

  const dismissAlert = () => {
    trackCloseCountdownAlert(daysRemaining);
    localStorage.setItem(TRIAL_DISMISSED_KEY, daysRemaining.toString());
    setShowAlert(false);
  };

  const renderBookACallButton = (buttonText: string) => {
    return (
      <TextButton
        onClick={() => {
          trackAlertBookACall(daysRemaining);
          openDiscoveryCall();
        }}
        data-testid="book-a-call-button"
      >
        {buttonText}
      </TextButton>
    );
  };

  const renderUpgradePlanButton = (buttonText: string) => {
    return (
      <SelfPaymentModal
        isClosable
        initialStep={SelfPaymentStep.UPGRADE_PLAN}
        triggerButtonText={buttonText}
        trialDaysRemaining={daysRemaining}
      />
    );
  };

  const getAlertHeading = () => {
    if (daysRemaining === 0) {
      return 'Your Free Trial Ends Today';
    }
    if (daysRemaining === 1) {
      return 'Your Free Trial Ends Tomorrow';
    }
    return `${daysRemaining} Days Remaining in Your Free Trial`;
  };

  return (
    showAlert && (
      <Alert type={daysRemaining > 4 ? 'warning' : 'error'} noBg dismissible onDismiss={dismissAlert} mt={5}>
        <Text fontSize={2} fontFamily="semiBold">
          {getAlertHeading()}
        </Text>

        <Text fontSize={2} fontFamily="regular" display="flex">
          {daysRemaining > DAYS_REMAINING_WARNING ? (
            <>
              {renderBookACallButton('Book a setup call')}
              &nbsp;with one of our experts or&nbsp;
              {renderUpgradePlanButton('upgrade your plan now.')}
            </>
          ) : (
            <>
              {renderUpgradePlanButton('Upgrade your plan')}
              &nbsp;to retain access to your Beacon account or&nbsp;
              {renderBookACallButton('book a setup call')}
              &nbsp;with one of our experts.
            </>
          )}
        </Text>
      </Alert>
    )
  );
}

import { graphql } from '../../../generated/gql';
import { SearchPurchaseOrdersByExternalBoardIdWithDeepCargosQuery } from '../../../generated/graphql';

export const searchPurchaseOrdersByExternalBoardIdWithDeepCargosQuery = graphql(/* GraphQL */ `
  query searchPurchaseOrdersByExternalBoardIdWithDeepCargos(
    $page: Int! = 0
    $size: Int! = 20
    $sort: [SearchSortCriteria!]
    $externalBoardId: String!
    $nestedSearchTerm: String
  ) {
    searchPurchaseOrdersByExternalBoardId(
      page: $page
      size: $size
      sort: $sort
      externalBoardId: $externalBoardId
      nestedSearchTerm: $nestedSearchTerm
    ) {
      page
      pageSize
      totalPages
      totalRecords
      pos {
        id
        createdOn
        createdByName
        customerSupplierId
        poNumber
        supplierName
        buyerName
        buyerId
        forwarderName
        cargoReadyDate
        deliveryDate
        orderConfirmedDate
        orderDate
        isWatched
        labels {
          id
          name
        }
        originLocation {
          name
          id
        }
        destinationLocation {
          name
          id
        }
        documents {
          id
          associatedId
          sizeBytes
          type
          name
          createdAt
          createdBy
          createdByUserId
          sizeBytes
          boardId
        }
        skus {
          skuNumber
          description
          quantity
          unitPrice {
            currency
            number
          }
          totalPrice {
            currency
            number
          }
        }
        associatedCargos {
          id
          errors {
            type
          }
          carrierShipmentId
          status
          freshnessTimestamp
          mode
          modeOfTracking
          placeholder
          timeline {
            legs {
              location {
                name
                unLocation {
                  code
                  countryName
                }
              }
              locationLabel
              onwardTransportInfo {
                mode
                vehicle {
                  displayName
                }
                isCurrent
              }
              isFinalDestination
              milestones {
                order
                label
                hasBeenAchieved
                otherDateInfoLabel
                otherDateInfo {
                  lastFetched
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  sourceName
                }
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  sourceName
                  lastFetched
                }
              }
            }
          }
          numberOfPackages
          customFields {
            key
            value
          }
          container {
            containerNumber
          }
          demurrageInfo {
            daysOnQuayPoD
            daysAtTsPort
            daysOffQuayDest
          }
          documents {
            id
            associatedId
            sizeBytes
            type
            name
            createdAt
            createdBy
            createdByUserId
            sizeBytes
            boardId
          }
          createdAt
          createdByName
          carrier {
            displayName
            nmftaCode
            iataCode
          }
          shipmentInfo {
            currentSplit
            totalSplits
          }
          shipmentReferences {
            id
            type
            value
            purchaseOrderId
          }
          slimPurchaseOrders {
            poNumber
            id
            supplierName
          }
          isWatched
          transportSummary {
            legs
            currentLeg
            haulagePairExists
            currentJourneyNumber
            currentMode
            currentVehicle {
              displayName
              identification {
                imoNumber
                licensePlate
              }
            }
            transportDistances {
              total {
                value
                unit
              }
              remaining {
                value
                unit
              }
            }
            prePrimaryLoad {
              location {
                id
                name
                facility {
                  iataCode
                }
                unLocation {
                  code
                  countryName
                }
              }
              milestoneViews {
                summary {
                  type
                  label
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                  }
                }
                full {
                  type
                  label
                  order
                  isStandalonePreviousDateMilestone
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                }
              }
            }
            origin {
              location {
                id
                name
                facility {
                  iataCode
                }
                unLocation {
                  code
                  countryName
                }
              }
              milestoneViews {
                summary {
                  type
                  label
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                  }
                }
                full {
                  type
                  label
                  order
                  isStandalonePreviousDateMilestone
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                }
              }
            }
            primaryLoad {
              location {
                id
                name
                facility {
                  iataCode
                }
                unLocation {
                  code
                  countryName
                }
              }
              milestoneViews {
                summary {
                  type
                  label
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                  }
                  hasBeenAchieved
                }
                full {
                  type
                  label
                  order
                  isStandalonePreviousDateMilestone
                  hasBeenAchieved
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                }
              }
            }
            transshipments {
              location {
                id
                name
                facility {
                  iataCode
                }
                unLocation {
                  code
                  countryName
                }
              }
              milestoneViews {
                summary {
                  type
                  label
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                  }
                  hasBeenAchieved
                }
              }
            }
            primaryDischarge {
              location {
                id
                name
                facility {
                  iataCode
                }
                unLocation {
                  code
                  countryName
                }
              }
              milestoneViews {
                summary {
                  type
                  label
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                  }
                  hasBeenAchieved
                }
                full {
                  type
                  label
                  order
                  isStandalonePreviousDateMilestone
                  hasBeenAchieved
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                }
              }
            }
            postPrimaryDischarge {
              location {
                id
                name
                facility {
                  iataCode
                }
                unLocation {
                  code
                  countryName
                }
              }
              milestoneViews {
                summary {
                  type
                  label
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                  }
                  hasBeenAchieved
                }
                full {
                  type
                  label
                  order
                  isStandalonePreviousDateMilestone
                  hasBeenAchieved
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                }
              }
            }
            destination {
              location {
                id
                name
                facility {
                  iataCode
                }
                unLocation {
                  code
                  countryName
                }
              }
              milestoneViews {
                summary {
                  type
                  label
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                  }
                  hasBeenAchieved
                }
                full {
                  type
                  label
                  order
                  isStandalonePreviousDateMilestone
                  hasBeenAchieved
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    timeframeStart {
                      date
                      timestamp
                      zone
                    }
                    timeframeEnd {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                }
              }
            }
            warehouse {
              location {
                id
                name
                facility {
                  iataCode
                }
                unLocation {
                  code
                  countryName
                }
                address {
                  address1
                  city
                  postcode
                  country
                }
              }
              milestoneViews {
                summary {
                  type
                  label
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                  }
                  hasBeenAchieved
                }
                full {
                  type
                  label
                  order
                  isStandalonePreviousDateMilestone
                  hasBeenAchieved
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                  otherDateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                    sourceName
                    order
                  }
                }
              }
            }
          }
        }
        version
      }
    }
  }
`);

export type BoardOrders =
  SearchPurchaseOrdersByExternalBoardIdWithDeepCargosQuery['searchPurchaseOrdersByExternalBoardId'];

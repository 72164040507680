import { Box, Button, Text } from '@beacon-devops/components';
import { Controller, useForm } from 'react-hook-form';
import { FlexContainer, RequiredTextInput } from '@components/common';
import { trackNumberOfShipments } from '../../../../analytics';
import { bookCall } from '../../../../utils';
import { SelfPaymentStep } from '../../steps';
import { useSelfPaymentModalContext } from '../../useSelfPaymentModalContext';

interface UpgradePlanProps {
  trialEnded: boolean;
}

export function UpgradePlan({ trialEnded }: UpgradePlanProps) {
  const { changeStep } = useSelfPaymentModalContext();

  const { control, getValues } = useForm();

  const choosePlan = () => {
    trackNumberOfShipments(getValues('numberOfShipments'));
    changeStep(SelfPaymentStep.CHOOSE_PLAN);
  };
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <FlexContainer flexDirection="column">
        {trialEnded ? (
          <Text fontFamily="regular" fontSize={2}>
            To continue using Beacon, please proceed to checkout where you
            <br /> can select a payment method. Alternatively, speak to one of our
            <br /> experts by clicking the button below.
          </Text>
        ) : (
          <Text fontFamily="regular" fontSize={2}>
            Unlock full access by purchasing tracking credits or speak with our
            <br /> experts who can help find the best solution for you.
          </Text>
        )}

        <Box mt={4}>
          <Text fontFamily="medium" fontSize={2} mb={1}>
            How many shipments do you move per month?
          </Text>
          <Controller
            name="numberOfShipments"
            control={control}
            rules={{ required: true }}
            render={RequiredTextInput('', 'Enter an approximate number')}
            defaultValue=""
          />
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mt={4}>
          <Button
            width="215px"
            variant="outlined"
            color="info"
            onClick={() => {
              const step = trialEnded ? 'trial-ended-book-a-call' : 'upgrade-plan_book-a-call';
              bookCall(step, getValues('numberOfShipments'));
            }}
            type="button"
            data-testid="book-a-call-button"
          >
            Speak with an expert
          </Button>
          <Text fontSize={2} color="secondaryShades.3">
            or
          </Text>
          <Button width="215px" color="primary" onClick={choosePlan} type="button" data-testid="checkout-button">
            Proceed to checkout
          </Button>
        </Box>
      </FlexContainer>
    </Box>
  );
}

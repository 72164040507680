import { sendEvent } from 'beaconAnalytics';

const featureName = 'subscription';

export function trackCloseCountdownAlert(daysRemaining: number) {
  sendEvent({
    featureName,
    eventName: 'trial-countdown-close-alert',
    data: {
      daysRemaining,
    },
  });
}

export function trackAlertBookACall(daysRemaining: number) {
  sendEvent({
    featureName,
    eventName: 'trial-countdown-book-a-call',
    data: {
      daysRemaining,
    },
  });
}

import { graphql } from '../../../generated/gql';
import { BoardQuery } from '../../../generated/graphql';

export const boardQuery = graphql(/* GraphQL */ `
  query board($externalBoardId: String!) {
    board(externalBoardId: $externalBoardId) {
      boardId
      externalBoardId
      boardName
      boardType
      createdAt
      customerId
      customerName
      createdByName
      filtersUpdatedByName
      filtersUpdatedAt
      locked
      createdBy
      public
      mode
      freeTracking
      cargoCount
      orderCount
      appliedSearchFilters {
        data {
          id
          value
        }
        field
      }
      appliedMacros {
        name
        arguments
      }
    }
  }
`);

export type BoardFromBoardQuery = BoardQuery['board'];
